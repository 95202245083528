import React, { useState, useRef, useEffect } from "react"
import fullHDImg from '../images/full-hd.webp';
import allDevicesImg from '../images/all-devices.webp';
// import fourkImg from '../images/4k.webp';
import HdIcon from '@mui/icons-material/Hd';
import FourKIcon from '@mui/icons-material/FourK';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function PricingCards({ onClick, isSelected, planName, planCode, planPrice, discountDetails, planHourLimit, planTerm, resolution, disabled, index, setQuantity, quantity }) {
    const planNameRef = useRef();
    return (
        <>
            <div
                onClick={disabled ? null : (e) => onClick(index, planNameRef.current)}
                className={`pricingN-cols plans ${isSelected ? 'selectedPrice' : ''} ${disabled ? 'disabled' : ''}`} style={disabled ? { cursor: "not-allowed" } : { cursor: "pointer" }}>
                <ul>
                    <li style={disabled ? { cursor: "not-allowed" } : { cursor: "pointer" }} className="pricingN-heading">
                        <div className={`radio-custom  ${isSelected ? 'active' : ''}`}>
                            <input style={disabled ? { cursor: "not-allowed" } : { cursor: "pointer" }} className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                            <span ref={planNameRef} className="pricing-tableHeading">{planName}</span>
                        </div>
                    </li>
                    {/* <li className="pricingN-subHeading"><i className="fas fa-rupee-sign"></i> {planPrice}</li> */}
                    <li className="pricingN-subHeading">
                        {planName === "TopUp" && (
                            <RemoveIcon
                                onClick={() =>
                                    quantity > 1 && setQuantity((pre) => pre - 1)
                                }
                            />
                        )}
                        <i className="fas fa-rupee-sign"></i>
                        &nbsp;
                        {planName === "TopUp"
                            ? (Number(planPrice * quantity))
                            : discountDetails ? <span className="bold-strikethrough">{planPrice}</span> : planPrice
                        }
                        {planName !== "TopUp" ? "\u00A0" : null}
                        {discountDetails && discountDetails.type === "percentage"
                            ? planName !== "TopUp" ? planPrice - (planPrice * (discountDetails.amount / 100)) : null
                            : discountDetails && discountDetails.type === "fixed"
                                ? planName !== "TopUp" ? planPrice - discountDetails.amount : null
                                : null
                        }

                        {/* {discountDetails && discountDetails.type === "percentage" ? planPrice - (planPrice * discountDetails.discountPercentage / 100) : () => planPrice - discountDetails.discountAmount} */}
                        {/* {discountDetails 
                        ? discountDetails.type == "percentage" 
                            ? () => planPrice - (planPrice * discountDetails.discountPercentage / 100) 
                            : discountDetails.type == "fixed" ? () => planPrice - discountDetails.discountAmount
                        : () => {
                            { planName === "TopUp" 
                               ? ( Number(planPrice * quantity)) 
                               : planPrice
                            }
                            {planName === "TopUp" && (
                                <AddIcon onClick={() => setQuantity((pre) => pre + 1)} />
                            )}
                          }
                        :
                        } */}
                        {/* {planName === "TopUp" && <button>Confirm</button>} */}

                        {planName === "TopUp" && (
                            <AddIcon onClick={() => setQuantity((pre) => pre + 1)} />
                        )}
                    </li>
                    {/* <li>
                        <p>
                            <i className="fas fa-hourglass-start"></i>
                            {planHourLimit} Hours
                        </p>
                    </li> */}
                    <li>
                        <p>
                            <i className="fas fa-hourglass-start"></i>
                            {planName !== "TopUp" ? planHourLimit : Number(planHourLimit * quantity)} Hours
                        </p>
                    </li>
                    <li>
                        <p>
                            {
                                resolution === "720p"
                                    ?
                                    <HdIcon fontSize="large" />
                                    :
                                    resolution === "1080p"
                                        ?
                                        <img
                                            src={fullHDImg}
                                            alt={resolution}
                                        />
                                        : <FourKIcon fontSize="large" />

                            }
                            {resolution ? `Upto ${resolution}` : "Depending on Base plan"}
                        </p>
                    </li>
                    <li>
                        <p>
                            <img src={allDevicesImg} alt="All Devices" />
                            {planCode === "GTXPremium" || planCode === "GTXUltimate" ? "18GB / 6GB" : planCode === "TopUp" ? "Depending on Base plan" : "20GB / 16GB"}
                        </p>
                    </li>
                    <li>
                        <p>
                            {
                                planCode === "GTXPremium" || planCode === "GTXUltimate"
                                    ?
                                    <CloseIcon fontSize="large" />
                                    :
                                    <CheckIcon fontSize="large" />

                            }
                            {planCode === "GTXPremium" || planCode === "GTXUltimate" ? "Disabled" : planCode === "TopUp" ? "Depending on Base plan" : "Enabled"}
                        </p>
                    </li>

                    {/* <li>
                        <p>
                            <img src={allDevicesImg} alt="All Devices" />
                            All devices
                        </p>
                    </li> */}
                    <li>
                        <p>
                            <i className="fas fa-calendar-alt"></i>
                            {planTerm ? planTerm + 'Days' : 'Depending on Base plan'}
                        </p>
                    </li>
                </ul>
            </div>
        </>
    )
}